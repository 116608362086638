@tailwind base;
@tailwind components;
@tailwind utilities;

.active {
	color: #03c988;
	transition: all 0.5s;
}

.disabled {
	color: #c3c3c3;
	pointer-events: none;
}

@layer base {
	body {
		@apply font-primary text-lg leading-8 text-white scrollable;
	}
	.scrollable {
		@apply scrollbar-thin scrollbar-thumb-accent scrollbar-track-gray-100;
	}
	.svg-link {
		@apply font-primary fill-secondary text-white font-bold;
	}
	.bold {
		@apply font-bold;
	}
	.h2 {
		@apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
	}
	.h3 {
		@apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
	}
	.h4 {
		@apply font-primary font-semibold text-[20px] leading-[30px];
	}
	.subtitle {
		@apply font-primary font-normal text-[20px] leading-[20px] italic;
	}
	.link {
		@apply font-semibold href;
	}
	.href {
		@apply fill-primary hover:text-[#03C988] ease-linear transition-all duration-150;
	}
	.btn {
		@apply rounded font-primary text-white font-bold bg-[#2C74B3] ease-linear transition-all duration-75 
		active:bg-secondary focus:bg-secondary hover:bg-secondary uppercase text-sm px-6 py-3 disabled:bg-slate-200 disabled:pointer-events-none;
	}
	.btn-no-background {
		@apply text-primary font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1  active:text-secondary focus:text-secondary hover:text-secondary ease-linear transition-all duration-150;
	}
	.btn-sm {
		@apply h-[48px] px-6 text-sm;
	}
	.btn-lg {
		@apply h-[56px] px-10 text-base;
	}
	.btn-link {
		@apply text-base;
	}
	.section {
		@apply py-8 lg:py-24 flex items-center md:h-screen min-h-screen;
	}
}
